import { ObjectId } from 'mongodb';
import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import { FormResponse } from '../types/form.types';
import { SchedulerResponse } from '../types/scheduler.types';
import { GetDevicesPaginateOpt } from '../types/devices.types';
import { PresentationResponse } from '../types/presentation.types';

import { ApiServiceErr, MutOptions, axiosApi } from './apiService';

export interface DeviceRegisterBody {
  name: string;
}

export interface DeviceInfo {
  totalSpace: number;
  freeSpace: number;
  brand: string;
  ipAddress: string;
  manufacturer: string;
  resolution: string;
  userAgent: string;
  _id: string;
  [key: string]: any;
  touchEnable: boolean;
}

export interface DeviceRegisterResponse {
  deviceId: string;
  id: string;
  name: string;
  mac: string;
  status: boolean;
  organization: string;
  _id: string;
  deviceInfo: DeviceInfo;
  createdAt: string;
  updatedAt: string;
}

interface Snapshot {
  key: string;
  url: string;
  uploadedTime: string;
  fileName: string;
}

// TODO: Has to be changed
export interface DeleteBody {
  id: string;
}
export interface DeleteSnapBody {
  key: string;
}

export interface UpdateDeviceResponse {
  _id: ObjectId;
}

export interface UpdateDeviceBody {
  id: string;
  name?: string;
  assignedPresentation?: string;
  scheduler?: string;
  form?: string;
}

export interface DeviceLog {
  action: string;
  Date: Date;
}

export interface VerifyPinBody {
  deviceId: string;
  deviceToken: string;
}

export interface GetDeviceProps extends DeviceRegisterResponse {
  assignedPresentation?: PresentationResponse;
  scheduler: SchedulerResponse;
  form: FormResponse;
  debugMode: boolean;
}

export interface UpdateDeviceDebugModeResponse extends DeviceRegisterResponse {
  debugMode: boolean;
}

export const useRegisterDevice = (
  deviceData?: VerifyPinBody,
  opt?: MutOptions<DeviceRegisterResponse>,
) =>
  useMutation<DeviceRegisterResponse, ApiServiceErr, DeviceRegisterBody>(
    async (data) => {
      const response = await axiosApi.post(
        `/devices/register/${deviceData?.deviceId}`,
        { ...data, token: deviceData?.deviceToken },
      );
      return response.data;
    },
    opt,
  );

export const useVerifyPin = (opt?: MutOptions<VerifyPinBody>) =>
  useMutation<VerifyPinBody, ApiServiceErr, number>(async (pinCode) => {
    const response = await axiosApi.post(`/pass-key/verify/${pinCode}`);
    return response.data;
  }, opt);

export const useGetDevices = ({
  limit,
  page,
  sortby,
  order,
  search = '',
}: GetDevicesPaginateOpt) =>
  useQuery<PagingResponse<DeviceRegisterResponse[]>, ApiServiceErr>(
    [
      `/devices/all?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}`,
    ],
    async () => {
      const response = await axiosApi.get(
        `/devices/all?limit=${limit}&page=${page}&sortby=${sortby}&order=${order}&search=${search}`,
      );
      return response.data;
    },
  );

export const useGetDevice = (id: string) =>
  useQuery<GetDeviceProps, ApiServiceErr>(['/devices', id], async () => {
    const response = await axiosApi.get(`/devices/${id}`);
    return response.data;
  });

export const useUpdateDevice = (opt?: MutOptions<UpdateDeviceResponse>) =>
  useMutation<UpdateDeviceResponse, ApiServiceErr, UpdateDeviceBody>(
    async (data) => {
      const response = await axiosApi.put(`/devices/update-device`, data);
      return response.data;
    },
    opt,
  );

export const useUpdateDeviceDebugMode = (
  opt?: MutOptions<UpdateDeviceDebugModeResponse>,
) =>
  useMutation<UpdateDeviceDebugModeResponse, ApiServiceErr, string>(
    async (deviceID) => {
      const response = await axiosApi.put(`/devices/toggleDebug/${deviceID}`);
      return response.data;
    },
    opt,
  );

export const useGetDeviceDebugMode = (id?: string) =>
  useQuery<boolean, ApiServiceErr>([`/devices/mode/${id}`], async () => {
    if (id) {
      const response = await axiosApi.get(`/devices/mode/${id}`);
      return response.data;
    }
    return [];
  });

export const useRequestSnapshot = (opt?: MutOptions<UpdateDeviceResponse>) =>
  useMutation<UpdateDeviceResponse, ApiServiceErr, string>(async (deviceId) => {
    const response = await axiosApi.post(
      `/devices/screen-capture/${deviceId}`,
      {},
    );
    return response.data;
  }, opt);

export const useGetDeviceLogs = (id?: string) =>
  useQuery<DeviceLog[], ApiServiceErr>(
    ['/devices/logs?id=${id}', id],

    async () => {
      if (id) {
        const response = await axiosApi.get(`/devices/logs?id=${id}`);
        return response.data;
      }
      return [];
    },
  );

export const useDeleteDevice = (opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/devices/remove/${data?.id}`);
    return response.data;
  }, opt);

export const useGetSnapshots = (deviceId: string) =>
  useQuery<Snapshot[], ApiServiceErr>(
    ['/capture'],
    async () => {
      const response = await axiosApi.get(`/capture/get/${deviceId}`);
      return response.data;
    },
    {
      enabled: false,
    },
  );

export const useDeleteSnapshot = (deviceId: string, opt?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DeleteSnapBody>(async (data) => {
    const response = await axiosApi.delete(`/capture/delete/snap/${deviceId}`, {
      data,
    });
    return response.data;
  }, opt);
