import LogsContainer from './components/LogsContainer';
import News from './components/canvas-editor-v2/components/Widgets/components/News';
import WeatherWidgetV2 from './components/canvas-editor-v2/components/Widgets/components/WeatherWidgetV2';
import SurveyEditor from './components/survey-editor/SurveyEditor';
import ActivityLogsPage from './containers/activityLogs/ActivityLogsPage';
import OrganizationPage from './containers/admin/organizations/OrganizationPage';
import UserPage from './containers/admin/users/UserPage';
import AssetsPage from './containers/assets/AssetsPage';
import ForgotPasswordPage from './containers/auth/forgotPassword/ForgotPasswordPage';
import SignInPage from './containers/auth/signIn/SignInPage';
import SignUpPage from './containers/auth/signUp/SignUpPage';
import CanvasEditorV2Page from './containers/canvas-editor-v2/CanvasEditorV2Page';
import CategoryPage from './containers/categories/CategoryPage';
import DevicesPage from './containers/devices/DevicesPage';
import ViewDevice from './containers/devices/ViewDevice';
import DisplayListPage from './containers/displays/displayList/DisplayList';
import DisplaySignInPage from './containers/displays/signIn/DisplaySignInPage';
import EventPage from './containers/event/EventPage';
import FormPublicPreview from './containers/form-public-preview/FormPublicPreview';
import FormsPage from './containers/form/FormsPage';
import FormPreview from './containers/form/formResponse/FormPreview';
import FormResponsesPage from './containers/form/formResponse/FormResponsesPage';
import HomePage from './containers/home/Home';
import KvsGroupPage from './containers/kvs/KvsGroupPage';
import KvsPage from './containers/kvs/KvsPage';
import Layout from './containers/layout/Layout';
import NotFoundPage from './containers/not-found/NotFoundPage';
import PresentationEditorPage from './containers/presentation-editor/PresentationEditorPage';
import PresentationPreviewPage from './containers/presentation-preview/PresentationPreviewPage';
import PresentationPublicPreviewPage from './containers/presentation-public-preview/PresentationPublicPreviewPage';
import PresentationPage from './containers/presentations/PresentationPage';
import ScheduledPresentationPreviewPage from './containers/preview-scheduled-presentations/PreviewScheduledPresentationPage';
import ProfilePage from './containers/profile/ProfilePage';
import SchedulerPage from './containers/scheduler/SchedulerPage';
import ShowPresentationFormPreview from './containers/show-Presentation-Form-Preview/ShowPresentationFormPreview';
import SlidesPage from './containers/slide/SlidesPage';
import UsersPage from './containers/users/UsersPage';
import { useAuth } from './context/auth-context';
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom';

interface RequireAuthProps {
  comp: JSX.Element;
}

const RequireAuth = ({ comp }: RequireAuthProps) => {
  // Ref https://github.com/remix-run/react-router/blob/dev/examples/auth/src/App.tsx#L130

  const { isLoggedIn } = useAuth();
  const location = useLocation();
  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={'/sign-in'} state={{ from: location }} replace />;
  }

  return comp;
};

export default function NavigationRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<RequireAuth comp={<Layout />} />}>
          <Route path={'/'} element={<HomePage />} />
          <Route path={'/profile'} element={<ProfilePage />} />
          <Route path={'/users'} element={<UsersPage />} />
          <Route path={'/devices'} element={<DevicesPage />} />
          <Route path={'/device/:id'} element={<ViewDevice />} />
          <Route path={'/presentations'} element={<PresentationPage />} />
          <Route path={'/assets'} element={<AssetsPage />} />
          <Route path={'/admin/organizations'} element={<OrganizationPage />} />
          <Route path={'/admin/users/:id'} element={<UserPage />} />
          <Route path={'/categories'} element={<CategoryPage />} />
          <Route path={'/scheduler'} element={<SchedulerPage />} />
          <Route path={'/kvs'} element={<KvsPage />} />
          <Route path={'/kvs/:id'} element={<KvsGroupPage />} />
          <Route path={'/event/:id'} element={<EventPage />} />
          <Route path={'/admin/activity-log'} element={<ActivityLogsPage />} />

          {/* Forms */}
          <Route path={'/forms'} element={<FormsPage />} />
          <Route
            path={'/forms/responses/:id'}
            element={<FormResponsesPage />}
          />
          <Route path={'/forms-preview/:id'} element={<FormPreview />} />

          {/* Editor */}
          <Route path={'/slides'} element={<SlidesPage />} />
          <Route
            path={'/presentations-editor/:id'}
            element={<PresentationEditorPage />}
          />
        </Route>
        <Route
          path={'/presentation-preview/:id'}
          element={<PresentationPreviewPage />}
        />
        <Route path={'/display-login'} element={<DisplaySignInPage />} />
        <Route path={'/display-list'} element={<DisplayListPage />} />
        <Route
          path={'/presentation-public-preview/:id'}
          element={<PresentationPublicPreviewPage />}
        />
        <Route path={'/sign-up'} element={<SignUpPage />} />
        <Route path={'/forgot-password'} element={<ForgotPasswordPage />} />
        <Route path={'/sign-in'} element={<SignInPage />} />
        <Route
          path={'/editor-v2/:id'}
          element={<RequireAuth comp={<CanvasEditorV2Page />} />}
        />
        <Route
          path={'scheduled/presentation-preview/:deviceId'}
          element={<ScheduledPresentationPreviewPage />}
        />
        <Route
          path={'form-public-preview/:formId'}
          element={<FormPublicPreview />}
        />
        <Route
          path={'show/:deviceId'}
          element={
            <>
              <LogsContainer />
              <ShowPresentationFormPreview />
            </>
          }
        />
        <Route path={'/survey-editor/:id'} element={<SurveyEditor />} />
        <Route path={'/weather-widget'} element={<WeatherWidgetV2 />} />

        <Route path={'/news/:newsType'} element={<News />} />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </BrowserRouter>
  );
}
