import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import { Button, Grid, Typography, Switch } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';

import { Wrapper } from '../styles';
import { notifyMsg } from '../../../configs';
import { useSnack } from '../../../util/useSnack';
import { UserRole } from '../../../types/user.types';
import { useAuth } from '../../../context/auth-context';
import { displayDistance } from '../../../util/dateTime';
import {
  useDeleteDevice,
  useUpdateDeviceDebugMode,
} from '../../../api-http/devices';

interface DeviceInfoProps {
  data: any;
}

const DeviceInfo: FC<DeviceInfoProps> = ({ data }) => {
  const { name, deviceId, status, deviceInfo, lastOnline, debugMode } =
    data || {};
  const { brand, resolution, touchEnable } = deviceInfo || {};

  const [debugModeSwitchChecked, setDebugModeSwitchChecked] =
    useState(debugMode);

  const { currentUser } = useAuth();
  const confirm = useConfirm();
  const { showErrSnack, showSuccessSnack } = useSnack();
  const navigate = useNavigate();

  const { error: deleteError, mutate: removeDevice } = useDeleteDevice({
    onSuccess: () => {
      navigate('/devices');
      showSuccessSnack(notifyMsg.DEVICE_DELETED_SUCCESSFULLY);
    },
    onError: () =>
      deleteError
        ? showErrSnack(deleteError.msg)
        : showErrSnack(notifyMsg.FAILED_TO_REMOVE_DEVICE),
  });

  const { mutate: mutateDebugMode } = useUpdateDeviceDebugMode({
    onSuccess: (res) => {
      setDebugModeSwitchChecked(res.debugMode);
      showSuccessSnack(notifyMsg.DEVICE_DEBUG_MODE_CHANGED_SUCCESSFULLY);
    },
    onError: (err) =>
      showErrSnack(
        err ? err.msg : notifyMsg.FAILED_TO_CHANGE_DEVICE_DEBUG_MODE,
      ),
  });

  useEffect(() => {
    setDebugModeSwitchChecked(debugMode);
  }, [debugMode]);

  const handleDelete = async (id: string) => {
    try {
      await confirm({
        description: 'Are you sure you want to remove this device?',
        confirmationText: 'Yes',
      });
      removeDevice({ id });
    } catch (err) {}
  };

  const handleDebugModeChange = () => {
    mutateDebugMode(deviceId);
  };

  return (
    <Wrapper>
      <Grid container>
        <Grid>
          <Typography
            variant="h5"
            marginBottom={3}
            fontWeight={'bold'}
            gutterBottom
          >
            {name}
            <CastConnectedIcon style={{ marginLeft: '10px' }} />
          </Typography>
        </Grid>
        <Grid>
          <Typography
            variant="h6"
            fontSize={11}
            fontWeight={'bold'}
            align="right"
            bgcolor={status ? 'green' : '#db6363'}
            color={'white'}
            borderRadius={5}
            padding={'5px'}
            width={'62px'}
            height={'24px'}
            marginTop={'-10px'}
            marginLeft={'8px'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            textAlign={'center'}
            boxShadow={'0px 4px 4px rgba(0, 0, 0, 0.25)'}
          >
            {status ? 'Online' : 'Offline'}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Typography fontSize={15} fontWeight={500}>
            Device Id
          </Typography>
          <Typography color={'gray'} fontSize={12} variant="body2">
            {deviceId}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize={15} fontWeight={500}>
            Device Brand Name
          </Typography>
          <Typography color={'gray'} fontSize={12} variant="body2">
            {brand}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize={15} fontWeight={500}>
            Resolution
          </Typography>
          <Typography color={'gray'} fontSize={12} variant="body2">
            {resolution}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize={15} fontWeight={500}>
            last Online
          </Typography>
          <Typography color={'gray'} fontSize={12} variant="body2">
            {displayDistance(lastOnline)}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography fontSize={15} fontWeight={500}>
            Touch Enabled
          </Typography>
          <Typography color={'gray'} fontSize={12} variant="body2">
            {touchEnable ? 'Yes' : 'No'}
          </Typography>
        </Grid>
        {currentUser?.role === UserRole.superAdmin && (
          <Grid
            item
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            marginTop={'20px'}
            width={'100%'}
          >
            <Typography fontSize={15} fontWeight={500}>
              Developer Mode
            </Typography>
            <Switch
              checked={debugModeSwitchChecked}
              onChange={handleDebugModeChange}
              color="warning"
            />
          </Grid>
        )}
        <Grid
          item
          display="flex"
          justifyContent="flex-end"
          alignItems="flex-end"
          marginTop={'20px'}
          width={'100%'}
        >
          <Button
            variant="outlined"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => handleDelete(deviceId)}
            style={{
              textAlign: 'center',
              width: '140px',
            }}
          >
            <Typography
              style={{
                marginTop: '2px',

                fontSize: '10px',
              }}
            >
              Remove Device
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default DeviceInfo;
