import React, { FC, useState } from 'react';
import {
  Paper,
  IconButton,
  InputBase,
  Grid,
  Card as MuiCard,
  CardContent,
  Button as MuiButton,
  debounce,
  LinearProgress,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';

import { DialogModal } from '../../../components';
import { CanvasPreviewV2 } from '../../../components/canvas-preview-v2/CanvasPreviewV2';
import { useGetPaginateSlide } from '../../../api-http/slides-v2';
import { DEBOUNCE_TIME } from '../../../configs';
import { OrderEnum } from '../../../types/pagination.types';
import { AddSlideResponse } from '../hooks/useDraftEdit';

const MAX_SLIDES = 6;

const useStyles = makeStyles(() => ({
  selected: {
    border: '2px solid #2464c0',
  },
}));

const StatusContainer = muiStyled(Paper)(({ theme }) => ({
  backgroundColor: '#e7ebef',
  ...theme.typography.body2,
  padding: theme.spacing(3),
  marginTop: 25,
  minHeight: 300,
}));

const Card = muiStyled(MuiCard)({
  cursor: 'pointer',
  minHeight: 250,
  '&:hover': {
    border: '1px solid gray',
  },
});

const Title = styled('p')`
  font-weight: bold;
  font-size: 15px;
  text-align: center;
  margin: 15px 0 0 0;
`;

const Button = muiStyled(MuiButton)({
  marginTop: 15,
  float: 'right',
});

interface AddSlideModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSlideAdd: (slide: AddSlideResponse) => void;
}

const AddSlideModal: FC<AddSlideModalProps> = ({
  open,
  setOpen,
  onSlideAdd,
}) => {
  const classes = useStyles();
  const [selectedId, setSelectedId] = useState<number | null>(null);
  const [searchText, setSearchText] = useState<string>('');
  const { data: list, isLoading: getAllSlidesIsLoading } = useGetPaginateSlide({
    sortby: 'createdAt',
    order: OrderEnum.desc,
    limit: MAX_SLIDES,
    page: 1,
    search: searchText,
  });
  const slides = list?.data || [];
  return (
    <DialogModal
      open={open}
      onClose={() => {
        setOpen(false);
        setSearchText('');
      }}
      width={'70%'}
      title="Add New Slide"
    >
      <small style={{ color: 'gray' }}>
        Due to performance we can show only {MAX_SLIDES} slide previews. Search
        by title to get other slides
      </small>

      <Paper
        component="form"
        sx={{
          p: '2px 4px',
          display: 'flex',
          alignItems: 'center',
          width: 400,
          m: '20px 0px',
        }}
        elevation={1}
      >
        <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
          <SearchIcon />
        </IconButton>
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          placeholder="Search By Slide Title"
          onChange={debounce(
            (event) => setSearchText(event.target.value),
            DEBOUNCE_TIME,
          )}
        />
      </Paper>

      {getAllSlidesIsLoading && <LinearProgress />}
      <StatusContainer elevation={1}>
        <Grid container sx={{ height: 300, overflowY: 'auto' }}>
          {slides.map((slide, i) => {
            return (
              <Grid item key={i} md={4} px={2} mb={2}>
                <Card
                  className={selectedId === i ? classes.selected : undefined}
                  onClick={() => {
                    setSelectedId(i);
                  }}
                  elevation={selectedId === i ? 2 : 0}
                >
                  <CardContent>
                    <CanvasPreviewV2
                      json={slide?.projectData}
                      canvasId={slide?._id}
                      style={{
                        height: 200,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    />
                    <Title>{slide?.title}</Title>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </StatusContainer>
      <Button
        variant="contained"
        onClick={() => {
          if (selectedId !== null) {
            onSlideAdd(slides[selectedId]);
            setSelectedId(null);
            setSearchText('');
          }
        }}
        disabled={selectedId === null}
      >
        Add Slide
      </Button>
    </DialogModal>
  );
};

export default AddSlideModal;
