import { RRule } from 'rrule';
import { SchedulerResponse } from '../types/scheduler.types';
import { EventResponse } from '../types/event.types';

const today = new Date();
const startOfTheDay = new Date(today.setHours(0, 0, 0, 0));
const endOfTheDay = new Date(today.setHours(23, 59, 59, 999));

const setTimeToCurrentDate = (time: string) => {
  const currentDate = new Date();
  const [hours, mins, secs] = time.split(':').map(Number);
  currentDate.setHours(hours, mins, secs, 0);

  return currentDate;
};

const getEventSchedules = (
  event: EventResponse,
  startOfTheDay: Date,
  endOfTheDay: Date,
) => {
  const { presentationSchedule } = event;

  const rruleOptions = {
    ...presentationSchedule,
    dtstart: new Date(presentationSchedule.dtstart),
    ...(presentationSchedule.until && {
      until: new Date(presentationSchedule.until),
    }),
  };

  delete rruleOptions.bymonthday;
  const rule = new RRule(rruleOptions);
  return rule.between(startOfTheDay, endOfTheDay);
};

export default (scheduler: SchedulerResponse) => {
  const { events } = scheduler;

  if (!events.length) {
    return scheduler.defaultPresentation;
  }

  for (const event of events) {
    const schedules = getEventSchedules(event, startOfTheDay, endOfTheDay);

    if (schedules.length) {
      for (let i = 0; i < schedules.length; i++) {
        const currentDateAndTime = new Date();
        const startDateAndTime = setTimeToCurrentDate(event.startTime);
        const endDateAndTime = setTimeToCurrentDate(event.endTime);

        if (
          currentDateAndTime >= startDateAndTime &&
          currentDateAndTime <= endDateAndTime
        ) {
          return event.presentation;
        }
      }
    }
  }
};
