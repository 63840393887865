import { IFRAME_WIDGETS } from '../canvas-editor-v2/config';
import { handleVideoFromFabricObject } from '../canvas-editor-v2/lib/FabricJs/utils/handleVideo';
import handleWorldClockAndDate from '../canvas-editor-v2/lib/FabricJs/utils/handleWorldClockAndDate';
import { getMaxSize } from '../canvas-editor-v2/utils/ratio';
import { fabric } from 'fabric';
import React, { CSSProperties, FC, useEffect, useRef, useState } from 'react';

const { WORLD_CLOCK, DATE, IFRAME } = IFRAME_WIDGETS;

interface CanvasPreviewV2Props {
  json: any;
  style?: CSSProperties;
  canvasId?: string;
  width?: number;
  height?: number;
}

export const CanvasPreviewV2: FC<CanvasPreviewV2Props> = ({
  style = {},
  json,
  canvasId = 'canvas',
  width: deviceWidth,
  height: deviceHeight,
}) => {
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [initiate, setInitiate] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const initCanvas = () => {
    const canvas = new fabric.Canvas(canvasId, {
      containerClass: 'minimap',
      selection: false,
      width: deviceWidth,
      height: deviceHeight,
    });
    for (const object of json?.objects) {
      object.selectable = false;
      if (object?.data?.type === 'video') {
        object.src = '';
      }
    }
    canvas.loadFromJSON(json, async () => {
      for (const obj of canvas.getObjects()) {
        if (obj.data?.type === 'video') {
          await handleVideoFromFabricObject(canvas, obj);
        } else {
          canvas?.bringToFront(obj);
        }
      }
      canvas.hoverCursor = 'default';
    });
    fabric.util.requestAnimFrame(function render() {
      canvas?.renderAll();
      fabric.util.requestAnimFrame(render);
    });
    setCanvas(canvas);
  };

  useEffect(() => {
    initCanvas();
  }, [json]);

  const handleResize = () => {
    if (ref.current && canvas) {
      const { width: canvasWidth, height: canvasHeight } =
        ref.current?.getBoundingClientRect();
      const realSize = {
        realWidth: json?.deviceInfo?.width || 1920,
        realHeight: json?.deviceInfo?.height || 1080,
      };

      const { width, height } = getMaxSize({
        canvasWidth,
        canvasHeight,
        width: realSize.realWidth,
        height: realSize.realHeight,
      });
      const ratio = width / realSize.realWidth;
      if (!initiate) {
        const canvasEditorContainer = document.getElementById(
          `canvas-preview-container-${canvasId}`,
        );
        const canvasContainer =
          canvasEditorContainer?.getElementsByClassName('minimap');
        for (const object of json?.objects) {
          const params = {
            canvas,
            obj: object,
            canvasContainer,
            ratio,
            isPreview: true,
            isTime: true,
          };
          switch (object?.data?.type) {
            case IFRAME:
              const iframe = document.createElement('iframe');
              iframe.src = object?.data.src;
              iframe.id = 'canvas-iframe';
              iframe.allow = 'autoplay; encrypted-media';
              canvasContainer?.[0].appendChild(iframe);

              iframe.style.left = `${object.left * ratio}px`;
              iframe.style.top = `${object.top * ratio}px`;
              iframe.style.width = `${object.width * object.scaleX * ratio}px`;
              iframe.style.height = `${
                object.height * object.scaleY * ratio
              }px`;
              break;
            case WORLD_CLOCK:
              handleWorldClockAndDate({ ...params });
              break;
            case DATE:
              params.isTime = false;
              handleWorldClockAndDate({ ...params });
              break;
          }
        }
        setInitiate(true);
      }
      canvas.setZoom(ratio);
      canvas.setHeight(height);
      canvas.setWidth(width);
      ref.current.getElementsByClassName(
        'minimap',
        // @ts-ignore;
      )[0].style = `width: ${width}px; height: ${height}px; position: relative; user-select: none; overflow: hidden;`;
      canvas.renderAll();
    }
  };
  useEffect(() => {
    if (!deviceWidth) {
      handleResize();
    }
    window.addEventListener('resize', handleResize, false);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [canvas]);

  return (
    <div
      id={
        canvasId
          ? `canvas-preview-container-${canvasId}`
          : 'canvas-preview-container'
      }
      ref={ref}
      style={{
        height: '100%',
        ...style,
      }}
    >
      <canvas id={canvasId} />
    </div>
  );
};
