export const SUCCESS = 'success';
export const ERROR = 'error';

export const SOCKET_EVENTS = {
  updateDevice: 'updateDevice',
  updateSlide: 'updateslide',
  dataConnection: 'dataConnection',
  editSlide: 'editSlide',
  refreshSocket: 'refreshSocket',
  updatePresentation: 'updatePresentation',
};

export const EVENTS = {
  screenCapture: 'screenCapture',
  clientConnect: 'clientConnect',
  clientDisconnect: 'clientDisconnect',
  deviceOnline: 'deviceOnline',
  deviceOffline: 'deviceOffline',
  deviceRegister: 'deviceRegister',
  removeDevice: 'removeDevice',
  uploadSnapShot: 'uploadSnapShot',
  toggleDeviceMode: 'toggleDeviceMode',
};
