import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { io } from 'socket.io-client';

import { useGetDisplayPresentation } from '../../api-http/displays';
import { AlertBar } from '../../components/AlertBar';
import { CircularProgress, Box } from '@mui/material';
import { metaTitle } from '../../util/tabTitle';
import PresentationPreview from '../../components/preview/PresentationPreview';
import { WS_URL } from '../../configs';
import { useGetScheduler } from '../../api-http/scheduler';
import schedulePresentations from '../../util/schedulePresentations';
import { Loader } from 'react-feather';
import DisplayError from '../../components/common/ApiErrorDisplay';

const socket = io(WS_URL);

const ScheduledPresentationPreviewPage: FC = () => {
  metaTitle('ScheduledPreview');

  const [presentationId, setPresentationId] = useState<string | undefined>('');

  const params = useParams<{ deviceId: string }>();

  const { deviceId } = params;

  let intervalId: ReturnType<typeof setTimeout>;

  const {
    data: schedulerData,
    error: apiError,
    isLoading: loadScheduler,
    refetch: refetchScheduler,
  } = useGetScheduler(deviceId);

  const scheduleUpdate = () => {
    if (schedulerData) {
      const id = schedulePresentations(schedulerData);
      if (id != presentationId) {
        setPresentationId(id);
      }
    }
  };

  useEffect(() => {
    // TODO: Find a proper way to do this
    clearInterval(intervalId);
    intervalId = setInterval(() => {
      scheduleUpdate();
    }, 5000);
    // remove interval on component unmount
    return () => clearInterval(intervalId);
  }, [schedulerData]);

  const { data, error, isLoading, refetch } = useGetDisplayPresentation(
    presentationId || schedulerData?.defaultPresentation,
  );
  const navigate = useNavigate();

  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    if (!isConnected) {
      socket.connect();
    }
  }, [isConnected]);

  useEffect(() => {
    socket.once('connect', () => {
      setIsConnected(true);
    });

    socket.once('updatePresentation', (updatedId) => {
      if (updatedId === data?._id) {
        refetch();
      }
    });

    socket.once('deletePresentation', (deletedId) => {
      if (deletedId === data?._id) {
        navigate('/display-list');
      }
    });

    socket.once('deleteSlide', (updatedPresentationsList) => {
      if (
        updatedPresentationsList &&
        updatedPresentationsList.includes(data?._id)
      ) {
        refetch();
      }
    });

    socket.once('disconnect', () => {
      setIsConnected(false);
    });

    socket.once('dataConnection', (eventObject) => {
      const { data } = eventObject;
      if (data.includes(deviceId)) {
        refetch();
        refetchScheduler();
      }
    });

    socket.once('updateDevice', (eventObject) => {
      const { data } = eventObject;
      if (data.includes(deviceId)) {
        refetch();
        refetchScheduler();
      }
    });

    return () => {
      socket.removeAllListeners();
    };
  }, [data, refetch]);

  if (error) {
    return <AlertBar severity="error" msg={error?.msg} />;
  }
  if (isLoading) {
    return (
      <Box
        sx={{ display: 'flex', height: '100vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={100} />
      </Box>
    );
  }

  if (loadScheduler) {
    return <Loader />;
  }

  return apiError ? (
    <DisplayError msg={apiError.msg} />
  ) : (
    <PresentationPreview presentation={data} />
  );
};

export default ScheduledPresentationPreviewPage;
