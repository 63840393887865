import Dexie from 'dexie';

const ASSETS_DB = 'advertexDB';

let db: null | Dexie = null;

export const getById = async (db: any, id: string, table: string) => {
  return await db[table].get(id);
};

export const getData = async (db: any, table: string) => {
  return await db[table].toArray();
};

export const createDatabase = async () => {
  db = new Dexie(ASSETS_DB);
  // Create two tables: assets and newAssets
  db.version(1).stores({
    assets: 'id,blob',
    newAssets: 'id,blob',
  });
  await db.open();
  return db;
};

export const getDB = async () => {
  return db;
};

export const addItemToDb = async (db: any, item: any, table: string) => {
  const existingItem = await getById(db, item.id, table);

  if (existingItem) {
    return;
  }
  await db.transaction('rw', db[table], () => {
    db[table].add(item);
  });
};

export const clearAllInDb = async (db: any, table: string) => {
  await db.transaction('rw', db[table], () => {
    db[table].clear();
  });
};

export const transferDataAndClear = async (db: any) => {
  await clearAllInDb(db, 'assets');

  const itemsToTransfer = await db['newAssets'].toArray();

  for (const item of itemsToTransfer) {
    await addItemToDb(db, item, 'assets');
  }

  await clearAllInDb(db, 'newAssets');
};
