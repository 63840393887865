/* eslint-disable no-console */
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { useIsOnline } from 'react-use-is-online';

import { SocketData } from '../../types';
import { errorMsg } from '../../configs';
import useSocket from '../../util/useSocket';
import { metaTitle } from '../../util/tabTitle';
import { SOCKET_EVENTS } from '../../util/constant';
import { useGetPublicForm } from '../../api-http/forms';
import { DeviceType } from '../../types/scheduler.types';
import { handleRefresh } from '../../util/handleRefresh';
import SurveyPreview from '../../components/SurveyPreview';
import NotAssignPage from '../not-assign-page/NotAssignPage';
import DisplayError from '../../components/common/ApiErrorDisplay';
import schedulePresentations from '../../util/schedulePresentations';
import ErrorDisplayPage from '../error-display-page/ErrorDisplayPage';
import { GetPresentationResponse } from '../../api-http/presentations';
import { useGetShowPresentationFormPreview } from '../../api-http/scheduler';
import { EditorProvider } from '../../components/survey-editor/EditorProvider';
import PresentationPreview from '../../components/preview/PresentationPreview';
import {
  AssetData,
  GetDisplayPresentationResponse,
  useGetDisplayPresentation,
} from '../../api-http/displays';
import {
  addItemToDb,
  clearAllInDb,
  createDatabase,
  getById,
  getData,
  transferDataAndClear,
} from '../../indexedDb';

function assetArraysAreEqual(
  array1: AssetData[],
  array2: AssetData[],
): boolean {
  // Check if the lengths are different
  if (array1.length !== array2.length) {
    return false;
  }

  // Check if every element in array1 is also present in array2
  return array1.every((element) => array2.includes(element));
}

const ShowPresentationFormPreview: FC = () => {
  metaTitle('Preview');

  const { isOnline } = useIsOnline();

  const [presentationId, setPresentationId] = useState<string | undefined>('');

  const { socket, socketErrors } = useSocket();
  const { deviceId } = useParams<{ deviceId: string }>();

  const [isCaching, setIsCaching] = useState(false);
  let intervalId: ReturnType<typeof setTimeout>;

  const [presentation, setPresentation] = useState<
    GetDisplayPresentationResponse | GetPresentationResponse
  >();

  const [isSyncing, setIsSyncing] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const {
    data: previewData,
    error: apiError,
    isLoading: isLoadingPreview,
    refetch: refetchPreview,
  } = useGetShowPresentationFormPreview(deviceId);

  const {
    data: formData,
    isLoading: isLoadingForm,
    refetch: refetchForm,
  } = useGetPublicForm(previewData?.form?._id);

  const scheduleUpdate = () => {
    if (
      previewData &&
      previewData.operatingType === DeviceType.SCHEDULED_DEVICE
    ) {
      const id = schedulePresentations(previewData.scheduler);

      if (!id) {
        setPresentationId(previewData?.scheduler?.defaultPresentation);
      } else if (id != presentationId) {
        setPresentationId(id);
      }
    }
  };

  useEffect(() => {
    if (socket) {
      const updateDeviceListener = (socketData: SocketData) => {
        const { data: deviceIdList } = socketData;
        if (deviceId && deviceIdList.includes(deviceId)) {
          refetchPreview();
        }
      };

      socket.on(SOCKET_EVENTS.updateDevice, updateDeviceListener);

      return () => {
        socket.off(SOCKET_EVENTS.updateDevice, updateDeviceListener);
      };
    }
  }, [socket]);

  useEffect(() => {
    if (previewData) {
      refetchForm();
    }
    clearInterval(intervalId);
    // TODO: Find a proper way to do this.
    intervalId = setInterval(() => {
      scheduleUpdate();
    }, 5000);
    return () => clearInterval(intervalId);
  }, [previewData]);

  const {
    data,
    error,
    isLoading,
    refetch: refetchPresentation,
  } = useGetDisplayPresentation(
    presentationId || previewData?.scheduler?.defaultPresentation,
  );

  useEffect(() => {
    const updateAssignedPresentation = (socketData: string) => {
      if (presentationId && socketData === presentationId) {
        refetchPresentation();
      }
    };
    if (socket) {
      socket.on(SOCKET_EVENTS.updatePresentation, updateAssignedPresentation);
    }
    return () => {
      socket.off(SOCKET_EVENTS.updatePresentation, updateAssignedPresentation);
    };
  }, [socket, presentationId]);

  useEffect(() => {
    const updateScheduler = (socketData: SocketData) => {
      const { data: deviceList } = socketData;

      if (deviceId && deviceList.includes(deviceId)) {
        refetchPreview();
      }
    };
    if (socket) {
      socket.on(SOCKET_EVENTS.dataConnection, updateScheduler);
    }
    return () => {
      socket.off(SOCKET_EVENTS.dataConnection, updateScheduler);
    };
  }, [socket]);

  const cacheAssets = async () => {
    const indexedDb = await createDatabase();
    await clearAllInDb(indexedDb, 'newAssets');

    const initialData = await getData(indexedDb, 'assets');
    setIsCaching(!initialData);

    const assetsList = data?.assets;

    if (!assetsList) {
      return;
    }

    for (const asset of assetsList) {
      try {
        const existingAsset = await getById(
          indexedDb,
          asset?.assetId,
          'assets',
        );

        if (existingAsset) {
          // Asset with the same ID already exists in the main DB
          // Transfer the existing blob to the newAssets DB
          await addItemToDb(
            indexedDb,
            { id: asset?.assetId, blob: existingAsset.blob },
            'newAssets',
          );
        } else {
          // Asset with the same ID doesn't exist in the main DB
          // Fetch and store the new blob
          const response = await fetch(asset?.assetSrc, {
            mode: 'cors',
          });
          const blob = await response.blob();

          let mimeType = 'video/mp4'; // Default to video/mp4
          if (asset?.assetSrc.endsWith('.png')) {
            mimeType = 'image/png';
          } else if (
            asset?.assetSrc.endsWith('.jpg') ||
            asset?.assetSrc.endsWith('.jpeg')
          ) {
            mimeType = 'image/jpeg';
          }

          const sampleBlob = new Blob([blob], { type: mimeType });
          // @ts-ignore
          await addItemToDb(
            indexedDb,
            { id: asset?.assetId, blob: sampleBlob },
            'newAssets',
          );
        }
      } catch (error) {
        console.log('Reading Error', error);
      }
    }

    setIsCaching(true);
    await transferDataAndClear(indexedDb);
    setPresentation(data);
    setIsCaching(false);
    setIsSyncing(false);
    setIsInitialLoad(false);
  };

  useEffect(() => {
    if (data) {
      const oldAssets = presentation?.assets || [];
      const newAssets = data?.assets || [];
      const areArraysEqual = assetArraysAreEqual(oldAssets, newAssets);

      if (isInitialLoad || !areArraysEqual) {
        setIsSyncing(true);
        cacheAssets();
      } else {
        handleRefresh();
      }
    }
  }, [data]);

  if (!previewData && !isLoadingPreview) {
    if (apiError || error) {
      return (
        <DisplayError
          msg={apiError?.msg || error?.msg || errorMsg.COMMON_ERROR}
        />
      );
    } else if (isOnline && socketErrors.length) {
      return <ErrorDisplayPage errorsArray={socketErrors} />;
    }
  }

  if (previewData && !previewData?.operatingType) {
    return <NotAssignPage />;
  }

  const isScheduledDevice =
    previewData?.operatingType === DeviceType.SCHEDULED_DEVICE;

  if (
    isLoading ||
    (isScheduledDevice && isLoadingPreview) ||
    isLoadingForm ||
    isCaching ||
    isInitialLoad
  ) {
    return (
      <Box
        sx={{ display: 'flex', height: '100vh' }}
        justifyContent="center"
        alignItems="center"
      >
        <CircularProgress size={100} />
      </Box>
    );
  }

  if (isScheduledDevice && presentation && !isCaching) {
    return (
      <PresentationPreview presentation={presentation} isSyncing={isSyncing} />
    );
  } else if (previewData?.operatingType === DeviceType.FORM_DEVICE) {
    if (formData?.formBody) {
      if (isLoadingForm) {
        return (
          <Box
            sx={{ display: 'flex', height: '100vh' }}
            justifyContent="center"
            alignItems="center"
          >
            <CircularProgress size={100} />
          </Box>
        );
      }

      return (
        <EditorProvider>
          <SurveyPreview data={formData} formId={previewData?.form?._id} />
        </EditorProvider>
      );
    }
  }

  return null;
};

export default ShowPresentationFormPreview;
