import React, { FC, useState } from 'react';
import { SlideWithOptions } from '../../api-http/presentations';
import { SortableList } from '../../components';
import { UserRole } from '../../types/user.types';
import { useIsUserRole } from '../../util/useIsUserRole';
import {
  IconButton,
  Paper,
  InputBase,
  Box,
  Button as MuiButton,
  Menu,
  Link,
  MenuItem,
  Divider,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material/styles';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import {
  AddCircleOutline,
  RemoveCircleOutline,
  Delete as DeleteIcon,
  Bolt,
} from '@mui/icons-material';

import { CanvasPreviewV2 } from '../../components/canvas-preview-v2/CanvasPreviewV2';
import { TRANSITIONS } from '../../components/preview/constants';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  gap: 20px;
`;

const Title = styled('h2')`
  font-weight: bold;
  margin: 15px 0 0 0;
`;

const SubTitle = styled('p')`
  font-weight: 500;
  font-size: 17px;
  margin: 15px 0 0 0;
`;

const Button = muiStyled(MuiButton)(({}) => ({
  minWidth: 200,
}));

interface PreviewListProp {
  slides: SlideWithOptions[];
  onSlideRemove: (uniqId: string) => void;
  onSlideOrderChange: (uniqId: any) => void;
  setSlides: (uniqId: any) => void;
  durationOnChange: (data: { duration: number; uniqId: string }) => void;
  transitionOnChange: (data: { animation: string; uniqId: string }) => void;
  initialTransitions: Array<string>;
  refetchPresentation: () => void;
  isPublished: boolean;
  updatedKvs: { [key: string]: string };
}

interface SlideCardProp extends Omit<PreviewListProp, 'slides'> {
  slide: SlideWithOptions;
  isViewOnly: boolean;
  uniqId: string;
  slideCount: number;
}

const SlideCard = ({
  slide,
  onSlideRemove,
  durationOnChange,
  transitionOnChange,
  uniqId,
  isPublished,
  updatedKvs,
  slideCount,
}: SlideCardProp) => {
  const { slideData, duration: slideDuration, animation } = slide;

  const [duration, setDuration] = useState(slideDuration / 1000);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!slideData) {
    return null;
  }

  const { projectData } = slideData;
  for (const object of projectData?.objects || []) {
    if (object?.data?.type === 'video') {
      object.src = '';
    } else if (object?.data?.type === 'kvs' && updatedKvs) {
      const kvsId = object?.data?.kvsId;
      if (updatedKvs[kvsId]) {
        object.text = updatedKvs[kvsId];
      }
    }
  }

  const isSingleSlide = slideCount === 1;

  return (
    <>
      <div
        style={{
          overflow: 'hidden',
          minWidth: 320,
          padding: '15px 0px',
          marginRight: 15,
          backgroundColor: 'rgba(0,0,30,0.1)',
          borderRadius: 5,
          marginLeft: isPublished || isSingleSlide ? 15 : 0,
        }}
      >
        <CanvasPreviewV2
          json={projectData}
          canvasId={uuidv4()}
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      </div>

      <div
        style={{
          width: '100%',
          height: '200px',
          overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Link
          href={`/editor-v2/${slide.slideId}?goBackUrl=${window.location.pathname}`}
          variant="body2"
          underline="always"
        >
          <Title>{slideData.title}</Title>
        </Link>
        {!isSingleSlide && (
          <div>
            <SubTitle>Duration (in seconds)</SubTitle>
            <Paper
              component="form"
              sx={{
                p: '2px 4px',
                display: 'flex',
                alignItems: 'center',
                width: 400,
              }}
            >
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                onClick={() => {
                  durationOnChange({ duration: (duration - 1) * 1000, uniqId });
                  setDuration(duration - 1);
                }}
                disabled={isPublished || duration - 1 === 0}
              >
                <RemoveCircleOutline />
              </IconButton>
              <Box
                sx={{
                  border: '1px solid gray',
                  borderRadius: '4px',
                  padding: '2px 20px',
                  margin: '0px 5px',
                }}
              >
                <InputBase
                  sx={{ ml: 1, width: 50 }}
                  value={duration}
                  inputProps={{ min: 0, style: { textAlign: 'center' } }}
                  type="number"
                  onChange={(e) => {
                    setDuration(Number(e.target.value));
                  }}
                  onBlur={() => {
                    durationOnChange({ duration: duration * 1000, uniqId });
                  }}
                  disabled={isPublished}
                />
              </Box>
              <IconButton
                type="button"
                sx={{ p: '10px' }}
                onClick={() => {
                  durationOnChange({ duration: (duration + 1) * 1000, uniqId });
                  setDuration(duration + 1);
                }}
                disabled={isPublished}
              >
                <AddCircleOutline />
              </IconButton>
            </Paper>
          </div>
        )}
      </div>
      <div
        style={{
          height: '180px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          marginRight: 20,
        }}
      >
        <Button
          id="transition-button"
          variant="outlined"
          startIcon={<Bolt />}
          onClick={handleClick}
          disabled={isPublished}
        >
          {animation} {animation !== 'none' && 'Transition'}
        </Button>
        <Menu
          id="transition-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'transition-button',
          }}
        >
          {TRANSITIONS.map((transition, i) => (
            <div key={i}>
              {i !== 0 && <Divider />}
              <MenuItem
                onClick={() => {
                  handleClose();
                  transitionOnChange({ animation: transition, uniqId });
                }}
                sx={{ width: 200 }}
              >
                {transition}
              </MenuItem>
            </div>
          ))}
        </Menu>
        <Button
          onClick={() => {
            onSlideRemove(uniqId);
          }}
          variant="outlined"
          color="error"
          startIcon={<DeleteIcon />}
          disabled={isPublished}
        >
          Delete
        </Button>
      </div>
    </>
  );
};

export const PreviewList: FC<PreviewListProp> = ({
  slides,
  refetchPresentation,
  isPublished,
  ...props
}) => {
  const isViewOnly = useIsUserRole([UserRole.user, UserRole.dataEditor]);

  return (
    <Container>
      <SortableList
        items={slides}
        onChange={(data) => {
          props.setSlides(data);
          const slides = [];
          for (const slide of data) {
            const { slideId } = slide;
            slides.push({
              ...slide,
              slideData: slideId,
            });
          }
          props.onSlideOrderChange(slides);
        }}
        renderItem={(item) => (
          <SortableList.Item id={item.id}>
            {!isPublished && slides.length > 1 && <SortableList.DragHandle />}
            <SlideCard
              key={item.id}
              {...props}
              slide={item}
              uniqId={item.id}
              isViewOnly={Boolean(isViewOnly)}
              refetchPresentation={refetchPresentation}
              isPublished={isPublished}
              slideCount={slides.length}
            />
          </SortableList.Item>
        )}
      />
    </Container>
  );
};
