import { DeviceInfo } from '../../../api-http/devices';
import { DeviceInfoWrapper } from '../styles';
import { Grid, Typography } from '@mui/material';
import { FC } from 'react';

interface AdditionalInfoProps {
  deviceInfo?: DeviceInfo;
}

const deviceData = [
  'Resolution',
  'User Agent',
  'Brand',
  'Manufacturer',
  'Ip Address',
];
let infoKeys: string[];

const camelCaseToWords = (str: string) => {
  // Split the string into an array of words
  const words = str.split(/(?=[A-Z])/);

  // Capitalize the first letter of each word and join them together
  return words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const deviceInfoValue = (infoName: string, displayValue: any) => {
  if (infoName === 'touchEnable') {
    if (displayValue === true) return 'Yes';
    else return 'No';
  } else return displayValue;
};

const AdditionalInfo: FC<AdditionalInfoProps> = ({ deviceInfo }) => {
  if (deviceInfo) {
    // eslint-disable-next-line
    const { _id, ...rest } = deviceInfo;
    infoKeys = deviceInfo && Object.keys({ ...rest });
  } else {
    infoKeys = [...deviceData];
  }

  const additionalInfo = infoKeys?.map((infoName: string) => {
    const volumeFields = ['totalSpace', 'freeSpace'];
    const byteToGBConversion = 1000000000;
    let displayValue;

    if (deviceInfo) {
      if (volumeFields.includes(infoName)) {
        displayValue = `${(deviceInfo[infoName] / byteToGBConversion).toFixed(
          2,
        )} GB`;
      } else {
        displayValue = deviceInfo[infoName];
      }

      const freeSpacePercent = (
        (deviceInfo?.freeSpace / deviceInfo?.totalSpace) *
        100
      ).toFixed(2);

      return (
        <Grid item xs={4} key={infoName}>
          <Typography
            style={{
              marginTop: '20px',
              fontSize: '15px',
              fontWeight: 12,
            }}
            fontWeight={600}
          >
            {camelCaseToWords(infoName)}
          </Typography>
          <Typography style={{ fontSize: '12px' }} variant="body2">
            {deviceInfoValue(infoName, displayValue)}
          </Typography>
          {infoName === 'freeSpace' ? (
            <meter value={freeSpacePercent} max="100">
              {freeSpacePercent}% free space
            </meter>
          ) : null}
        </Grid>
      );
    }
  });

  return (
    <DeviceInfoWrapper>
      <Grid container>
        <Grid item xs={10}>
          <Typography
            borderBottom={'1px solid white'}
            variant="h6"
            fontWeight="bold"
            gutterBottom
            paddingBottom={'10px'}
          >
            Device Information
          </Typography>
        </Grid>
        {additionalInfo}
      </Grid>
    </DeviceInfoWrapper>
  );
};
export default AdditionalInfo;
