import { FC } from 'react';
import { Grid, Image, Type, Video, ShoppingBag, Layers } from 'react-feather';
import styled from 'styled-components';
import Tabs from './Tabs/Tabs';
import ImageWidget from './Widgets/ImageWidget';
import ShapeWidget from './Widgets/ShapesWidget';
import TextWidget from './Widgets/TextWidget';
import ColorPicker from './Widgets/ColorPicker';
import { useEditorData } from '../lib/FabricJs';
import VideoWidget from './Widgets/VideoWidget';
import InfoWidgets from './Widgets/InfoWidgets';
import KvsWidget from './Widgets/KvsWidget';
import ObjectSettings from './Widgets/ObjectSettings';

interface StyleProps {
  color?: string;
}

const SidePanelContainer = styled.div<StyleProps>`
  background: ${({ color }) => color};
  display: flex;
  border-right: 1px solid lightgray;
`;

const tabs = [
  {
    title: 'Text',
    icon: <Type color="white" />,
    children: <TextWidget />,
  },
  {
    title: 'Images',
    icon: <Image color="white" />,
    children: <ImageWidget />,
  },
  {
    title: 'Videos',
    icon: <Video color="white" />,
    children: <VideoWidget />,
  },
  {
    title: 'Shapes',
    icon: <Grid color="white" />,
    children: <ShapeWidget />,
  },
  {
    title: 'Colors',
    children: <ColorPicker />,
  },
  {
    title: 'Apps',
    icon: <ShoppingBag color="white" />,
    children: <InfoWidgets />,
  },
  {
    title: 'KVS - Manage Dynamic Data',
    icon: <Layers color="white" />,
    children: <KvsWidget />,
  },
  {
    title: 'Settings',
    children: <ObjectSettings />,
  },
];

const SidePanel: FC = () => {
  const { activeTab } = useEditorData();
  return (
    <SidePanelContainer
      color={
        activeTab === 'Colors' || activeTab === 'Settings' ? 'white' : '#252627'
      }
    >
      <Tabs tabs={tabs} id="side-panel" />
    </SidePanelContainer>
  );
};

export default SidePanel;
