import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { Close as CloseIcon, Edit as EditIcon } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import { FC } from 'react';
import { CanvasPreviewV2 } from './CanvasPreviewV2';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  backDrop: {
    backdropFilter: 'blur(8px)',
    backgroundColor: 'rgba(0,0,30,0.8)',
  },
}));

const defaultStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 4,
  width: '72%',
  height: '85%',
};

const iconStyle = {
  color: 'white',
};

const editButtonStyle = {
  color: 'white',
  borderColor: 'white',
  marginRight: '15px',
  ':hover': {
    borderColor: 'white',
    backgroundColor: 'none',
  },
};

const HeaderText = styled.h2`
  color: white;
  font-size: 20px;
  margin: 0;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
`;

const Row = styled.div`
  display: flex;
`;

interface DialogModalProp extends React.PropsWithChildren {
  onClose: () => void;
  open: boolean;
  title?: string;
  style?: React.CSSProperties;
  json?: object;
  slideId?: string;
}

export const CanvasPreviewModalV2: FC<DialogModalProp> = ({
  open,
  onClose,
  title,
  style = {},
  json = {},
  slideId,
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={{
        classes: {
          root: classes.backDrop,
        },
      }}
    >
      <Box sx={[defaultStyle, style]}>
        <Header>
          <HeaderText>{title}</HeaderText>
          <Row>
            {slideId && (
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(`/editor-v2/${slideId}`);
                  window.location.reload();
                }}
                startIcon={<EditIcon />}
                sx={editButtonStyle}
              >
                Edit
              </Button>
            )}
            <IconButton aria-label="close" onClick={onClose} sx={iconStyle}>
              <CloseIcon />
            </IconButton>
          </Row>
        </Header>
        <CanvasPreviewV2
          json={json}
          style={{ display: 'flex', justifyContent: 'center' }}
        />
      </Box>
    </Modal>
  );
};
