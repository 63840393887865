const authMsg = {
  EMAIL_INVALID: 'Must be a valid email',
  EMAIL_REQUIRED: 'Email is required',
  PASSWORD_REQUIRED: 'Password is required',
  CURRENT_PASSWORD_REQUIRED: 'Current password is required',
  NEW_PASSWORD_REQUIRED: 'New password is required',
  PASSWORD_6CHAR_REQUIRED: 'Password must have at least 8 characters',
  PASSWORD_SPACES: 'Password cannot contain white space',
  PASSWORD_COMBINATION_REQUIRED:
    'Password must contain capital & simple letters, numbers and symbols',
  CONFIRM_PASSWORD_REQUIRED: 'Confirm Password is required',
  PASSWORD_UNMATCHED: 'Passwords must match',
  EMAIL_PASSWORD_INCORRECT: 'Incorrect email or password',
  PIN_CODE_REQUIRED: 'Pin Code is required',
  PASSWORD_MAXIMUM_16CHARACTERS: 'Password must have less than 16 characters',
};

const errorMsg = {
  SOMETHING_WRONG: 'Something went wrong',
  COMMON_ERROR:
    'Due to a network restrictions, the assigned scheduler cannot be downloaded at this moment',
  SOCKET_ERROR: 'Socket connectivity issue occurred',
};

const formMsg = {
  NAME_REQUIRED: 'Name is required',
  DEVICE_NAME_REQUIRED: 'Device Name is required',
  ORGANIZATION_REQUIRED: 'Organization is required',
  TITLE_REQUIRED: 'Title is required',
  FILE_NAME_REQUIRED: 'File name is required',
  TITLE_3CHAR_REQUIRED: 'Title should be of minimum 3 characters length',
  PASSWORD_REQUIREMENTS_FAILED:
    'Password should be 8-16 long and must include at least one special character,numeric value,upper-case and a lower-case value ',
  GROUP_NAME_REQUIRED: 'Group name is required',
  KVS_DESCRIPTION_REQUIRED: 'Description is required',
  KEY_REQUIRED: 'Key is required',
  VALUE_REQUIRED: 'Value is required',
  EVENT_PRESENTATION_REQUIRED: 'Please select a Presentation.',
  EVENT_FREQUENCY_REQUIRED: 'Please select a Frequency',
  EVENT_START_DATE_REQUIRED: 'Please select a start date',
  EVENT_START_DATE_CANNOT_BE_PAST: 'Start date cannot be a past date.',
  EVENT_END_DATE_CANNOT_BE_LOWER: 'End date cannot be lower than start date.',
  EVENT_END_DATE_REQUIRED: 'Please select an End Date',
  EVENT_START_TIME_REQUIRED: 'Please select a Start time',
  EVENT_END_TIME_REQUIRED: 'Please select an End time',
  EVENT_END_TIME_GREATER_THAN_START_TIME:
    'End time must be greater than start time',
  INVALID_KEY: 'Invalid key(only lowercase letters, numbers and underscore)',
  INVALID_GROUP_NAME:
    'Invalid group name(only lowercase letters, numbers and underscore)',
  SCHEDULER_NAME_REQUIRED: 'Scheduler Name is Required',
  TIMEZONE_REQUIRED: 'Timezone is Required',
  DESCRIPTION_REQUIRED: 'Description is Required',
  DEFAULT_PRESENTATION_REQUIRED: 'Default Presentation is Required',
  FORM_NAME_REQUIRED: 'Form Name is Required',
};

const notifyMsg = {
  ORGANIZATION_ADDED_SUCCESS: 'Organization successfully added',
  USER_ADDED_SUCCESS: 'User successfully added',
  ORGANIZATION_UPDATE_SUCCESS: 'Organization successfully updated',
  USER_DELETE_SUCCESS: 'User successfully deleted',
  DELETE_ATTEMPT_FAILED: 'Delete attempt failed',
  USER_UPDATE_SUCCESS: 'User details successfully updated',
  ASSET_ADDED_SUCCESS: 'Asset successfully added',
  APK_ADDED_SUCCESS: 'APK successfully added',
  ASSET_DELETE_SUCCESS: 'Asset successfully deleted',
  SCHEDULER_DELETE_SUCCESS: 'Scheduler successfully deleted',
  EVENT_DELETE_SUCCESS: 'Event successfully deleted',
  PRESENTATION_DELETE_SUCCESS: 'Presentation successfully deleted',
  PRESENTATION_CLONE_SUCCESS: 'Presentation successfully cloned',
  PRESENTATION_PUBLISHED_SUCCESS: 'Presentation successfully published',
  PRESENTATION_DETAILS_UPDATE_SUCCESS:
    'Presentation details successfully updated',
  PASSWORD_CHANGED_SUCCESS: 'Password changed successfully',
  PROFILE_DETAILS_UPDATE_SUCCESS: 'Profile details successfully updated',
  DYNAMIC_VALUES_ADDED_SUCCESS: 'Dynamic Values successfully added',
  SLIDE_NAME_UPDATE_SUCCESS: 'Slide name successfully updated',
  SLIDE_NAME_UPDATE_FAILED: 'Update slide name failed',
  SLIDE_DELETE_SUCCESS: 'Slide successfully deleted',
  SLIDE_CLONE_SUCCESS: 'Slide successfully cloned',
  ORGANIZATION_ENABLE_SUCCESS: 'Organization successfully enabled',
  ORGANIZATION_DISABLE_SUCCESS: 'Organization successfully disabled',
  CATEGORY_DELETE_SUCCESS: 'Category successfully deleted',
  CATEGORY_CREATE_SUCCESS: 'Category successfully created',
  CATEGORY_CREATE_FAILED: 'Category create attempt failed',
  CATEGORY_RENAME_SUCCESS: 'Category name successfully renamed',
  CATEGORY_RENAME_FAILED: 'Category rename attempt failed',
  DELETE_CONFIRMATION: 'Are you sure you want to delete?',
  PIN_INVALID: 'Invalid pin code',
  DEVICE_REGISTERED: 'Device successfully registered',
  CLIP_BOARD_COPIED_SUCCESS: 'Source copied',
  PRESENTATION_ASSIGNED: 'Assigned successfully',
  KVS_GROUP_CREATE_SUCCESS: 'KVS group successfully created',
  KVS_GROUP_CREATE_FAILED: 'KVS create attempt failed',
  KVS_GROUP_UPDATE_SUCCESS: 'KVS group successfully updated',
  KVS_GROUP_UPDATE_FAILED: 'KVS group update attempt failed',
  KVS_GROUP_REMOVE_SUCCESS: 'KVS group successfully deleted',
  KVS_GROUP_REMOVE_FAILED: 'KVS group delete attempt failed',
  FAILED_TO_REMOVE_DEVICE: 'Failed to remove device',
  DEVICE_DELETED_SUCCESSFULLY: 'Device has been removed successfully',
  FAILED_TO_CHANGE_DEVICE_DEBUG_MODE: 'Failed to change device debug mode',
  DEVICE_DEBUG_MODE_CHANGED_SUCCESSFULLY:
    'Device debug mode has been changed successfully',
  KVS_KEY_VALUE_UPDATE_SUCCESS: 'KVS key value successfully updated',
  KVS_KEY_VALUE_UPDATE_FAILED: 'KVS key value update attempt filed',
  KVS_KEY_VALUE_ADD_SUCCESS: 'KVS key value successfully added',
  KVS_KEY_VALUE_ADD_FAILED: 'KVS key value add attempt failed',
  KVS_KEY_VALUE_REMOVE_SUCCESS: 'KVS key value successfully deleted',
  KVS_KEY_VALUE_REMOVE_FAILED: 'KVS key value delete attempt failed',
  CAPTURE_REQUEST_SENT: 'Screen capture request send successfully',
  CAPTURE_REQUEST_SUCCESS: 'Screen captured successfully',
  CAPTURE_REQUEST_FAILED: 'Screen capture failed',
  EVENT_CREATE_FAILED: 'Event create attempt failed',
  EVENT_CREATE_SUCCESS: 'Event successfully created',
  SCREENSHOT_DELETED: 'Screenshot deleted successfully',
  SCREENSHOT_DELETE_FAILED: 'Screenshot delete failed',
  ASSIGNED_SCHEDULER: 'Scheduler assigned successfully',
  ASSIGNED_FORM: 'Form assigned successfully',
  SCHEDULER_ADDED_SUCCESS: 'Scheduler successfully added',
  SCHEDULER_UPDATE_SUCCESS: 'Scheduler successfully updated',
  SCHEDULER_UPDATE_FAILED: 'Scheduler update failed',
  SCHEDULER_CREATE_SUCCESS: 'Scheduler successfully created',
  SCHEDULER_CREATE_FAILED: 'Scheduler attempt failed',
  EVENT_UPDATED_SUCCESS: 'Event successfully updated',
  EVENT_UPDATED_FAILED: 'Event update attempt failed',
  FORM_UPDATE_SUCCESS: 'Form name successfully updated',
  FORM_UPDATE_FAILED: 'Form name update attempt failed',
  FORM_ADDED_SUCCESS: 'Form successfully added',
  FORM_DELETE_SUCCESS: 'Form successfully deleted',
  ASSETS_MAX_FILE_COUNT:
    'Maximum file count exceeded. You can upload up to 5 files.',
  ASSETS_IMAGE_VIDEO_ALLOWED: 'Only image and video files are allowed.',
  ASSETS_MAX_FILE_SIZE: 'File size exceeds the maximum limit of 100MB.',
  ASSETS_NAME_UPDATE_SUCCESS: 'Asset successfully updated',
  TOKEN_COPIED_SUCCESS: 'Token copied to clipboard',
  NEW_TOKEN_REGENERATED_SUCCESS: 'New token regenerated successfully',
};

const confirmationMsgs = {
  PRESENTATION_DELETE: 'Are you sure you want to delete this presentation?',
};

export { authMsg, errorMsg, formMsg, notifyMsg, confirmationMsgs };
